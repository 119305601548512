<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Einverständniserklärung</h4>

  <div>
    <div class="form-check">
      <input
        [formControl]="formGroup.controls.exclusiveInAustria"
        class="form-check-input"
        type="checkbox"
        id="exclusiveInAustria">

      <label
        class="form-check-label"
        for="exclusiveInAustria">
        Mir ist bewusst, dass es sich hierbei um eine Zusatzversicherung zu meiner Vollkasko-Versicherung handelt und ich bestätige, dass ich für das zu versichernde Fahrzeug eine Vollkasko-Versicherung bei einem in Österreich zugelassenen Versicherer abgeschlossen habe.
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.maximumCarWeightIsRespected"
        class="form-check-input"
        type="checkbox"
        id="maximumCarWeightIsRespected">

      <label
        class="form-check-label"
        for="maximumCarWeightIsRespected">
        Ich bestätige, dass mein Fahrzeug das zulässige Gesamtgewicht bis 3,5 t nicht überschreitet
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.automatedInsuranceStopAfterTenYears"
        class="form-check-input"
        type="checkbox"
        id="automatedInsuranceStopAfterTenYears">

      <label
        class="form-check-label"
        for="automatedInsuranceStopAfterTenYears">
        Ich nehme zur Kenntnis, dass die Versicherungsdeckung automatisch endet, wenn mein Fahrzeug ein Alter von 10 vollendeten Jahren ab Erstzulassung erreicht
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.automatedInsuranceStopAfter200kkm"
        class="form-check-input"
        type="checkbox"
        id="automatedInsuranceStopAfter200kkm">

      <label
        class="form-check-label"
        for="automatedInsuranceStopAfter200kkm">
        Ich nehme zur Kenntnis, dass keine Versicherungsdeckung besteht, sobald mein Fahrzeug eine Gesamtlaufleistung von max. 200.000 km erreicht
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.noBusinessUsage"
        class="form-check-input"
        type="checkbox"
        id="noBusinessUsage">

      <label
        class="form-check-label"
        for="noBusinessUsage">
        Ich bestätige, dass mein Fahrzeug nicht für gewerbsmäßige Zwecke verwendet wird (z.B. Paketdienst, Taxi, etc.)
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.communication"
        class="form-check-input"
        type="checkbox"
        id="communication">

      <label
        class="form-check-label"
        for="communication">
        Ich stimme der Vereinbarung zur elektronischen Kommunikation zu
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/05/Vereinbarung-zur-elektronischen-Kommunikation.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.gdpr"
        class="form-check-input"
        type="checkbox"
        id="gdpr">

      <label
        class="form-check-label"
        for="gdpr">
        Hiermit akzeptiere ich die Datenschutzerklärung
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/datenschutzerklaerung/"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.commonConditions"
        class="form-check-input"
        type="checkbox"
        id="commonConditions">

      <label
        class="form-check-label"
        for="commonConditions">
        Hiermit akzeptiere ich die Allgemeinen Vertragsbedingungen
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/11/AVB-GAP_Kaufpreiskasko-Versicherung_final.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.informationLetter"
        class="form-check-input"
        type="checkbox"
        id="informationLetter">

      <label
        class="form-check-label"
        for="informationLetter">
        Ich habe das Informationsblatt für Versicherungsprodukte gelesen und zu Kenntnis genommen
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/11/IPID-ID-GO_GAP-Versicherung_Kaufpreiskasko.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.sepaAllowed"
        class="form-check-input"
        type="checkbox"
        id="sepaAllowed">

      <label
        class="form-check-label"
        for="sepaAllowed">
        Ich ermächtige die Servo GmbH, Zahlungen zu folgenden Bedingungen von meinem Konto mittels SEPA-Lastschrift einzuziehen
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/06/Sepa-Mandat-Vorlage-Privat-Bestaetigung-fuer-Homepage.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.rightOfWithdrawal"
        class="form-check-input"
        type="checkbox"
        id="rightOfWithdrawal">

      <label
        class="form-check-label"
        for="rightOfWithdrawal">
        Widerrufs-Recht: Ich weiß, dass ich innerhalb von 14 Tagen ohne Grund von diesem Vertrag zurücktreten kann
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.wishesAndNeedsFulfilled"
        class="form-check-input"
        type="checkbox"
        id="wishesAndNeedsFulfilled">

      <label
        class="form-check-label"
        for="wishesAndNeedsFulfilled">
       Dieses Produkt entspricht meinen Wünschen und Bedürfnissen.
      </label>

      <br/>

      <small class="text-muted">
        <a href="mailto:office@myservo.at"
          target="_blank">
          Falls nicht, kannst du uns gerne hier kontaktieren.
        </a>
      </small>
    </div>
  </div>
</ng-container>
