import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class IbanValidator {
  static CODE_LENGTHS = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
  };

  static checksum(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const iban = String(value).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
      let digits = null;

      if (code === null) {
        return { invalidIban: true };
      }

      // check syntax and length
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!code || iban.length !== this.CODE_LENGTHS[code[1]]) {
        return { invalidIban: true };
      }

      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => this.uppercase(letter).toString());

      // final check
      return this.mod97(digits) === 1 ? null : { 'invalidIban': { value: value } };
    };
  }

  private static uppercase(substring: string): number {
    return substring.charCodeAt(0) - 55;
  }

  private static mod97(value: string): number {
    let checksum = parseInt(value.slice(0, 2));
    let fragment: string;

    for (let offset = 2; offset < value.length; offset += 7) {
      fragment = String(checksum) + value.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }

    return checksum;
  }
}
