<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Zahlungsoptionen</h4>

  <div class="row g-3">
    <div class="col-sm-6">
      <label
        for="iban"
        class="form-label">IBAN</label>

      <input
        [formControl]="formGroup.controls.iban"
        type="text"
        class="form-control"
        id="iban" />

      @if (formGroup.controls.iban.hasError('invalidIban')) {
        <div class="invalid-feedback">
          Bitte geben Sie einen gültigen IBAN an.
        </div>
      }

    </div>

    <div>
      <div class="mb-1">
        Zahlweise
      </div>

      <div class="form-check">
        <input
          [formControl]="formGroup.controls.paymentPeriod"
          id="yearly"
          name="paymentMethod"
          type="radio"
          class="form-check-input"
          [value]="PaymentPeriodEnum.YEARLY">

        <label
          class="form-check-label"
          for="yearly">Jährlich</label>
      </div>

      <div class="form-check">
        <input
          [formControl]="formGroup.controls.paymentPeriod"
          id="halfyear"
          name="paymentMethod"
          type="radio"
          class="form-check-input"
          [value]="PaymentPeriodEnum.HALFYEAR">

        <label
          class="form-check-label"
          for="halfyear">Halbjährlich</label>
      </div>

      <div class="form-check">
        <input
          [formControl]="formGroup.controls.paymentPeriod"
          id="quarterly"
          name="paymentMethod"
          type="radio"
          class="form-check-input"
          [value]="PaymentPeriodEnum.QUARTER">

        <label
          class="form-check-label"
          for="quarterly">Vierteljährlich</label>
      </div>

      <div class="form-check">
        <input
          [formControl]="formGroup.controls.paymentPeriod"
          id="monthly"
          name="paymentMethod"
          type="radio"
          class="form-check-input"
          [value]="PaymentPeriodEnum.MONTHLY">

        <label
          class="form-check-label"
          for="monthly">Monatlich</label>
      </div>
    </div>
  </div>
</ng-container>
