<ul class="list-group mb-3">
  <li class="list-group-item d-flex justify-content-between lh-sm">
    <div>
      <h6 class="my-0">
        {{ title }}
      </h6>

      @if (price) {
        <small class="text-body-secondary">
          Neupreis inkl. Zubehör
        </small>
      }
    </div>

    @if (price) {
      <span class="text-body-secondary">
      {{ price | currency:'EUR':'symbol':'1.2-2':'de-AT' }}
    </span>
    }
  </li>

  <li class="list-group-item d-flex justify-content-between">
    <span>Prämie ({{ premiumIntervalText }})</span>
    <strong>{{ premium | currency:'EUR':'symbol':'1.2-2':'de-AT' }}</strong>
  </li>
</ul>
