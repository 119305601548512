import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CountryEnum } from '@base/models/country.enum';
import { PersonFormModel } from '@base/models/form/person-form.model';

@Component({
  selector: 'app-person-data',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './person-data.component.html',
  styleUrl: './person-data.component.scss'
})
export class PersonDataComponent {

  @Input() formGroup!: FormGroup<PersonFormModel>;

  CountryEnum = CountryEnum;
}
