import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ProductDataComponent } from '../product-data/product-data.component';
import { LegalChecksComponent } from '../legal-checks/legal-checks.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GarantieSubmitModel } from '../../models/garantie-submit.model';
import { InsuranceDetailsComponent } from '@base/components/insurance-details/insurance-details.component';
import { PersonDataComponent } from '@base/components/person-data/person-data.component';
import { PaymentDataComponent } from '@base/components/payment-data/payment-data.component';
import { PersonFormModel } from '@base/models/form/person-form.model';
import { AddressFormModel } from '@base/models/form/address-form.model';
import { CountryEnum } from '@base/models/country.enum';
import { PaymentFormModel } from '@base/models/form/payment-form.model';
import { PaymentPeriodEnum } from '@base/models/payment-period.enum';
import { InsuranceType, InsuranceTypeEnum } from '@base/models/insurance-type.enum';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { GarantieApiService } from '../../services/garantie-api.service';
import { IbanValidator } from '../../../../../../../../src/app/shared/validators/iban.validator';

@Component({
  selector: 'app-garantie-form-wrapper',
  standalone: true,
  imports: [
    ProductDataComponent,
    InsuranceDetailsComponent,
    PersonDataComponent,
    PaymentDataComponent,
    LegalChecksComponent,
    LegalChecksComponent,
    FormsModule,
    ReactiveFormsModule,
    PageTitleComponent
  ],
  providers: [GarantieApiService],
  templateUrl: './garantie-form-wrapper.component.html',
  styleUrl: './garantie-form-wrapper.component.scss'
})
export class GarantieFormWrapperComponent implements OnInit {

  @Input() isPremiumSchutz!: boolean;
  @Input() premium!: number;
  @Input() servoAdvertiserId: string | null = null;

  @Output() submitted = new EventEmitter<void>();

  isLoading = false;

  fb = inject(FormBuilder);
  form = this.fb.group<GarantieFormModel>({
    person: this.fb.group<PersonFormModel>({
      firstname: new FormControl<string | null>(null, [Validators.required]),
      lastname: new FormControl<string | null>(null, [Validators.required]),
      email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
      phone: new FormControl<string | null>(null),
      address: this.fb.group<AddressFormModel>({
        houseNumber: new FormControl<string | null>(null, [Validators.required]),
        street: new FormControl<string | null>(null, [Validators.required]),
        city: new FormControl<string | null>(null, [Validators.required]),
        zip: new FormControl<string | null>(null, [Validators.required]),
        country: new FormControl<CountryEnum | null>(CountryEnum.AUSTRIA, [Validators.required])
      })
    }),
    product: this.fb.group<GarantieProductFormModel>({
      isPremiumSchutz: new FormControl<boolean | null>(null, [Validators.required])
    }),
    payment: this.fb.group<PaymentFormModel>({
      iban: new FormControl<string | null>(null, [Validators.required, IbanValidator.checksum()]),
      paymentPeriod: new FormControl<PaymentPeriodEnum | null>(null, [Validators.required])
    }),
    legalCheck: this.fb.group<LegalCheckFormModel>({
      communication: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      gdpr: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      commonConditions: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      informationLetter: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      sepaAllowed: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      rightOfWithdrawal: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      wishesAndNeedsFulfilled: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      })
    })
  });

  constructor(private garantieApiService: GarantieApiService) {
  }

  ngOnInit() {
    this
      .form
      .controls
      .product
      .controls
      .isPremiumSchutz
      .setValue(this.isPremiumSchutz, { emitEvent: false });

    this
      .form
      .controls
      .product
      .controls
      .isPremiumSchutz
      .valueChanges
      .subscribe(isPremiumSchutz => {
        if (isPremiumSchutz) {
          this.premium = 379;
        } else if (isPremiumSchutz === false) {
          this.premium = 199;
        }
      });

    this.checkForDataInUrl();
  }

  checkForDataInUrl(): void {
    const url = new URL(window.location.href);

    const personForm = this.form.controls.person.controls;

    if (!personForm.firstname.value && url.searchParams.has('firstname')) {
      personForm.firstname.setValue(url.searchParams.get('firstname'));
    }

    if (!personForm.lastname.value && url.searchParams.has('lastname')) {
      personForm.lastname.setValue(url.searchParams.get('lastname'));
    }

    if (!personForm.email.value && url.searchParams.has('email')) {
      personForm.email.setValue(url.searchParams.get('email'));
    }

    if (!personForm.phone.value && url.searchParams.has('phone')) {
      personForm.phone.setValue(url.searchParams.get('phone'));
    }

    if (!personForm.address.controls.street.value && url.searchParams.has('street')) {
      personForm.address.controls.street.setValue(url.searchParams.get('street'));
    }

    if (!personForm.address.controls.houseNumber.value && url.searchParams.has('houseNumber')) {
      personForm.address.controls.houseNumber.setValue(url.searchParams.get('houseNumber'));
    }

    if (!personForm.address.controls.city.value && url.searchParams.has('city')) {
      personForm.address.controls.city.setValue(url.searchParams.get('city'));
    }

    if (!personForm.address.controls.zip.value && url.searchParams.has('zip')) {
      personForm.address.controls.zip.setValue(url.searchParams.get('zip'));
    }

    const paymentForm = this.form.controls.payment.controls;

    if (!paymentForm.iban.value && url.searchParams.has('iban')) {
      paymentForm.iban.setValue(url.searchParams.get('iban'));
    }
  }


  submit(): void {
    this.isLoading = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.isLoading = false;
      return;
    }

    const url = new URL(window.location.href)

    this.garantieApiService
      .submit({
        person: { ...this.form.getRawValue().person },
        legalCheck: { ...this.form.getRawValue().legalCheck },
        productDetails: { ...this.form.getRawValue().product },
        payment: { ...this.form.getRawValue().payment },
        insuranceType: InsuranceTypeEnum.GARANTIE,
        initialPrice: 0, // has to be set for backend
        servoAdvertiserId: this.servoAdvertiserId,
        customerId: url.searchParams.get('customerId'),
      } as NonNullable<GarantieSubmitModel>)
      .subscribe({
        next: () => this.submitted.emit(),
        complete: () => this.isLoading = false
      });
  }

  protected readonly InsuranceTypeEnum = InsuranceTypeEnum;
  protected readonly InsuranceType = InsuranceType;
}

export interface GarantieFormModel {
  person: FormGroup<PersonFormModel>;
  product: FormGroup<GarantieProductFormModel>;
  payment: FormGroup<PaymentFormModel>;
  legalCheck: FormGroup<LegalCheckFormModel>;
}

export interface GarantieProductFormModel {
  isPremiumSchutz: FormControl<boolean | null>;
}

export interface LegalCheckFormModel {
  communication: FormControl<boolean>;
  gdpr: FormControl<boolean>;
  commonConditions: FormControl<boolean>;
  informationLetter: FormControl<boolean>;
  sepaAllowed: FormControl<boolean>;
  rightOfWithdrawal: FormControl<boolean>;
  wishesAndNeedsFulfilled: FormControl<boolean>;
}
