@if (!noPermission) {
  <div>
    <app-page-title
      [title]="'Kaufpreiskasko abschließen'"></app-page-title>

    @if (showRechner) {
      <div class="col-12 col-lg-6 m-auto">
        <app-kaufpreis-calculator
          (requestOffer)="onRequestOffer($event)"></app-kaufpreis-calculator>
      </div>
    } @else {
      @if (!submitted) {
        <app-kaufpreis-form-wrapper
          [premium]="premium"
          [price]="initialPrice"
          [servoAdvertiserId]="servoAdvertiserId"
          (submitted)="submitted = true"></app-kaufpreis-form-wrapper>
      }
    }

    @if (submitted) {
      <div
        class="alert alert-success text-center"
        role="alert">

        Ihr Antrag wurde erfolgreich versendet! <br />
        Sie können diese Seite nun schließen.
      </div>
    }
  </div>
}
