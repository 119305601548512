<form
  class="servo-card"
  [formGroup]="form"
  (ngSubmit)="submit()">

  <div class="card-header">
    <div class="title">
      <h5 class="mb-0 d-flex">
        Berechnen Sie Ihre Prämie
      </h5>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="mb-2">
        <div class="col-12">
          <span class="fw-medium">
            Garantieschutzbrief oder Prämienschutz
          </span>
        </div>

        <div class="col-md-12">
          <div class="mb-3 mt-3">
            <div class="form-check mb-3">
              <input
                [formControl]="form.controls.isPremiumSchutz"
                [value]="false"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1">

              <label
                class="form-check-label"
                for="flexRadioDefault1">
                Garantieschutzbrief
              </label>

              <small
                class="radio-hint"
                role="presentation"
                (click)="form.controls.isPremiumSchutz.setValue(false)"
                (keyup.enter)="form.controls.isPremiumSchutz.setValue(false)">
                <br/>
                Verlängerung der Herstellergarantie auf 5 Jahre <br/>
                inkludiert Schutz bei Gerätemängeln <br/>
                <span class="text-decoration-underline">Prämie: EUR 199,00 jährlich</span>
              </small>
            </div>

            <div class="form-check">
              <input
                [formControl]="form.controls.isPremiumSchutz"
                [value]="true"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked>

              <label
                class="form-check-label"
                for="flexRadioDefault2">
                Premiumschutz
              </label>

              <small
                class="radio-hint"
                role="presentation"
                (click)="form.controls.isPremiumSchutz.setValue(true)"
                (keyup.enter)="form.controls.isPremiumSchutz.setValue(true)">
                <br/>
                Verlängerung der Herstellergarantie auf 8 Jahre <br/>
                Elektro-Gerätekasko für Schäden durch Überspannung, Kurzschluss, Feuchtigkeit, Unfall oder Sturz <br/>
                Selbstbehalt im Schadensfall EUR 85,– <br/>
                <span class="text-decoration-underline">Prämie: EUR 379,00 jährlich</span>
              </small>
            </div>
          </div>
        </div>
      </div>

      @if (premium !== null) {
        <div class="col-12 mb-3">
          <div class="premium-wrapper">
            <div class="premium">
              {{ premium | currency:'EUR':'code':'1.2-2':'de-AT' }}
            </div>

            <small class="text-muted">
              (Jährliche Bruttoprämie)
            </small>
          </div>
        </div>

        <div class="col-12 mb-2">
          <button
            type="submit"
            class="btn btn-primary w-100">

            Jetzt abschließen
          </button>
        </div>
      }
    </div>
  </div>
</form>
