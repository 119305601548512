import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GarantieProductFormModel } from '../garantie-form-wrapper/garantie-form-wrapper.component';

@Component({
  selector: 'app-product-data',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './product-data.component.html',
  styleUrl: './product-data.component.scss'
})
export class ProductDataComponent {

  @Input() formGroup!: FormGroup<GarantieProductFormModel>;
}
