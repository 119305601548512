import { Injectable } from '@angular/core';
import { KaupfreisSubmitModel } from '../models/kaupfreis-submit.model';
import { Observable } from 'rxjs';
import { ApiService } from '@base/services/api.service';

@Injectable()
export class KaufpreisApiService extends ApiService {

  protected get serviceEndpoint(): string {
    return `${this.endpointBrokernet}/api/servo/kaufpreis-kasko/public`;
  }

  submit(data: KaupfreisSubmitModel): Observable<KaupfreisSubmitModel> {
    return this.http.post<KaupfreisSubmitModel>(this.serviceEndpoint + `/onboarding`, data);
  }
}
