<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Zu versicherndes Produkt</h4>

  <div class="row g-3">
    <div class="col-sm-4">
      <label
        for="productGroup"
        class="form-label">Versicherungsobjekt</label>

      <select
        [formControl]="formGroup.controls.insuredObjectType"
        class="form-select"
        id="productGroup">

        <option [ngValue]="KaufpreisInsuredObjectTypeEnum.PKW">PKW</option>
        <option [ngValue]="KaufpreisInsuredObjectTypeEnum.GELAENDEWAGEN">Geländewagen</option>
        <option [ngValue]="KaufpreisInsuredObjectTypeEnum.TRANSPORTER">Transporter</option>
        <option [ngValue]="KaufpreisInsuredObjectTypeEnum.WOHNMOBIL">Wohnmobil</option>
      </select>
    </div>

    <div class="col-sm-4">
      <label
        for="brand"
        class="form-label">Marke</label>

      <input
        [formControl]="formGroup.controls.brand"
        type="text"
        class="form-control"
        id="brand"/>
    </div>

    <div class="col-sm-4">
      <label
        for="model"
        class="form-label">Modell</label>

      <input
        [formControl]="formGroup.controls.model"
        type="text"
        class="form-control"
        id="model"/>
    </div>

    <div class="col-sm-4">
      <label
        for="serialNumber"
        class="form-label">FIN</label>

      <input
        [formControl]="formGroup.controls.fin"
        type="text"
        class="form-control"
        id="serialNumber"/>
    </div>

    <div class="col-sm-4">
      <label
        for="dateOfFirstRegistration"
        class="form-label">Datum der Erstzulassung</label>

      <input
        [formControl]="formGroup.controls.dateOfFirstRegistration"
        type="date"
        class="form-control"
        id="dateOfFirstRegistration"/>
    </div>

    <div class="col-sm-4">
      <label
        for="dateOfFirstRegistration"
        class="form-label">Kilometerstand</label>

      <input
        [formControl]="formGroup.controls.mileage"
        type="number"
        class="form-control"
        id="mileage"/>
    </div>
  </div>
</ng-container>
