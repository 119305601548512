<div class="row gx-5 gy-3 position-relative">
  <div class="col-md-5 col-lg-4 order-md-last">
    <div class="sticky-top">
      <h4 class="d-flex justify-content-between align-items-center mb-3">
      <span class="servo-text-primary">
        Ihr versichertes Produkt
      </span>
      </h4>

      <app-insurance-details
        [title]="InsuranceType.getTypeTranslation(InsuranceTypeEnum.KAUFPREIS)"
        [premium]="premium"
        [price]="price"></app-insurance-details>
    </div>
  </div>

  <div class="col-md-7 col-lg-8 mb-4">
    <form
      [formGroup]="form"
      (ngSubmit)="submit()">

      <app-person-data
        [formGroup]="form.controls.person"></app-person-data>

      <hr class="my-4">

      <app-product-data
        [formGroup]="form.controls.product"></app-product-data>

      @if (!objectToOld && !mileageToHigh) {
        <hr class="my-4">

        <app-payment-data
          [formGroup]="form.controls.payment"></app-payment-data>

        <hr class="my-4">

        <app-legal-checks
          [formGroup]="form.controls.legalCheck"></app-legal-checks>

        <hr class="my-4">

        <button
          [disabled]="isLoading"
          class="w-100 btn btn-primary"
          type="submit">

          @if(isLoading) {
            <span class="spinner-grow spinner-grow-sm me-2" aria-hidden="true"></span>
            <span role="status">Antrag wird versendet</span>
          } @else {
            <span>Antrag absenden</span>
          }
        </button>
      } @else if (objectToOld) {
        <p class="error-message">
          Sie können Ihr Fahrzeug nur versichern, wenn es nicht älter als 10 Jahre ist!
        </p>
      } @else if (mileageToHigh) {
        <p class="error-message">
          Sie können Ihr Fahrzeug nur versichern, wenn es weniger als 200.000 km hat!
        </p>
      }
    </form>
  </div>
</div>
