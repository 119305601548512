import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CurrencyPipe, JsonPipe } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { KaufpeisPremiumCalculationService } from '../../../../../../../../src/app/features/calculator-overview/services/kaufpeis-premium-calculation.service';

@Component({
  selector: 'app-kaufpreis-calculator',
  standalone: true,
  imports: [
    CurrencyPipe,
    PaginatorModule,
    ReactiveFormsModule,
    JsonPipe
  ],
  templateUrl: './kaufpreis-calculator.component.html',
  styleUrl: './kaufpreis-calculator.component.scss',
  providers: [KaufpeisPremiumCalculationService]
})
export class KaufpreisCalculatorComponent implements OnInit {

  @Output() requestOffer = new EventEmitter<{ initialPrice: number, premium: number }>();

  fb = inject(FormBuilder);
  kaufpreisPremiumCalculationService = inject(KaufpeisPremiumCalculationService);

  form = this.fb.group<KaufpreisCalculatorFormModel>({
    initialPrice: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(120000)
    ])
  });
  premium: number | null = null;

  ngOnInit() {
    this.form
      .valueChanges
      .subscribe(value => {
        if (value.initialPrice !== null) {
          this.premium = this.kaufpreisPremiumCalculationService.getPremium(value.initialPrice!);
        }
      });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.requestOffer.emit({
      initialPrice: this.form.getRawValue().initialPrice!,
      premium: this.premium!
    });
  }
}

export interface KaufpreisCalculatorFormModel {
  initialPrice: FormControl<null | number>;
}

