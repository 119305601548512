import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PaymentFormModel } from '@base/models/form/payment-form.model';
import { PaymentPeriodEnum } from '@base/models/payment-period.enum';

@Component({
  selector: 'app-payment-data',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './payment-data.component.html',
  styleUrl: './payment-data.component.scss'
})
export class PaymentDataComponent {

  @Input() formGroup!: FormGroup<PaymentFormModel>;
  protected readonly PaymentPeriodEnum = PaymentPeriodEnum;
}
