import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ProductDataComponent } from '../product-data/product-data.component';
import { InsuranceDetailsComponent } from '@base/components/insurance-details/insurance-details.component';
import { PersonDataComponent } from '@base/components/person-data/person-data.component';
import { PaymentDataComponent } from '@base/components/payment-data/payment-data.component';
import { LegalChecksComponent } from '../legal-checks/legal-checks.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { KaufpreisInsuredObjectTypeEnum } from '../../models/kaufpreis-insured-object-type.enum';
import { KaufpreisApiService } from '../../services/kaufpreis-api.service';
import { PersonFormModel } from '@base/models/form/person-form.model';
import { AddressFormModel } from '@base/models/form/address-form.model';
import { CountryEnum } from '@base/models/country.enum';
import { PaymentFormModel } from '@base/models/form/payment-form.model';
import { PaymentPeriodEnum } from '@base/models/payment-period.enum';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { InsuranceType, InsuranceTypeEnum } from '@base/models/insurance-type.enum';
import { KaupfreisSubmitModel } from '../../models/kaupfreis-submit.model';
import { IbanValidator } from '../../../../../../../../src/app/shared/validators/iban.validator';

@Component({
  selector: 'app-kaufpreis-form-wrapper',
  standalone: true,
  imports: [
    ProductDataComponent,
    InsuranceDetailsComponent,
    PersonDataComponent,
    PaymentDataComponent,
    LegalChecksComponent,
    LegalChecksComponent,
    FormsModule,
    ReactiveFormsModule,
    PageTitleComponent
  ],
  providers: [KaufpreisApiService],
  templateUrl: './kaufpreis-form-wrapper.component.html',
  styleUrl: './kaufpreis-form-wrapper.component.scss'
})
export class KaufpreisFormWrapperComponent implements OnInit {

  @Input() price!: number;
  @Input() premium!: number;
  @Input() servoAdvertiserId: string | null = null;

  @Output() submitted = new EventEmitter<void>();

  mileageToHigh = false;
  objectToOld = false;
  isLoading = false;

  fb = inject(FormBuilder);
  form = this.fb.group<KaufpreisFormModel>({
    person: this.fb.group<PersonFormModel>({
      firstname: new FormControl<string | null>(null, [Validators.required]),
      lastname: new FormControl<string | null>(null, [Validators.required]),
      email: new FormControl<string | null>(null, [
        Validators.required,
        Validators.email
      ]),
      phone: new FormControl<string | null>(null),
      address: this.fb.group<AddressFormModel>({
        houseNumber: new FormControl<string | null>(null, [Validators.required]),
        street: new FormControl<string | null>(null, [Validators.required]),
        city: new FormControl<string | null>(null, [Validators.required]),
        zip: new FormControl<string | null>(null, [Validators.required]),
        country: new FormControl<CountryEnum | null>(CountryEnum.AUSTRIA, [Validators.required])
      })
    }),
    product: this.fb.group<KaufpreisProductFormModel>({
      insuredObjectType: new FormControl<KaufpreisInsuredObjectTypeEnum | null>(KaufpreisInsuredObjectTypeEnum.PKW, [Validators.required]),
      brand: new FormControl<string | null>(null, [Validators.required]),
      model: new FormControl<string | null>(null, [Validators.required]),
      fin: new FormControl<string | null>(null, [Validators.required]),
      dateOfFirstRegistration: new FormControl<Date | null>(null, [Validators.required]),
      mileage: new FormControl<number | null>(null, [Validators.required])
    }),
    payment: this.fb.group<PaymentFormModel>({
      iban: new FormControl<string | null>(null, [
        Validators.required,
        IbanValidator.checksum()
      ]),
      paymentPeriod: new FormControl<PaymentPeriodEnum | null>(null, [Validators.required])
    }),
    legalCheck: this.fb.group<KaufpreisLegalCheckFormModel>({
      exclusiveInAustria: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      maximumCarWeightIsRespected: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      }),
      automatedInsuranceStopAfterTenYears: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      }),
      automatedInsuranceStopAfter200kkm: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      }),
      noBusinessUsage: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      communication: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      gdpr: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      commonConditions: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      informationLetter: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      sepaAllowed: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      rightOfWithdrawal: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      wishesAndNeedsFulfilled: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      })
    })
  });

  constructor(
    private kaufpreisApiService: KaufpreisApiService
  ) {
  }

  ngOnInit() {
    const productCtrls = this.form.controls.product.controls;

    productCtrls
      .mileage
      .valueChanges
      .subscribe(mileage => this.mileageToHigh = mileage !== null && mileage > 200000);

    productCtrls
      .dateOfFirstRegistration
      .valueChanges
      .subscribe(age => {
        if (age === null) {
          this.objectToOld = false;
          return;
        }
        const tenYearsAgo = new Date();
        tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
        this.objectToOld = new Date(age) ! <= tenYearsAgo;
      });

    this.checkForDataInUrl();
  }

  checkForDataInUrl(): void {
    const url = new URL(window.location.href);

    const personForm = this.form.controls.person.controls;

    if (!personForm.firstname.value && url.searchParams.has('firstname')) {
      personForm.firstname.setValue(url.searchParams.get('firstname'));
    }

    if (!personForm.lastname.value && url.searchParams.has('lastname')) {
      personForm.lastname.setValue(url.searchParams.get('lastname'));
    }

    if (!personForm.email.value && url.searchParams.has('email')) {
      personForm.email.setValue(url.searchParams.get('email'));
    }

    if (!personForm.phone.value && url.searchParams.has('phone')) {
      personForm.phone.setValue(url.searchParams.get('phone'));
    }

    if (!personForm.address.controls.street.value && url.searchParams.has('street')) {
      personForm.address.controls.street.setValue(url.searchParams.get('street'));
    }

    if (!personForm.address.controls.houseNumber.value && url.searchParams.has('houseNumber')) {
      personForm.address.controls.houseNumber.setValue(url.searchParams.get('houseNumber'));
    }

    if (!personForm.address.controls.city.value && url.searchParams.has('city')) {
      personForm.address.controls.city.setValue(url.searchParams.get('city'));
    }

    if (!personForm.address.controls.zip.value && url.searchParams.has('zip')) {
      personForm.address.controls.zip.setValue(url.searchParams.get('zip'));
    }

    const paymentForm = this.form.controls.payment.controls;

    if (!paymentForm.iban.value && url.searchParams.has('iban')) {
      paymentForm.iban.setValue(url.searchParams.get('iban'));
    }
  }

  submit(): void {
    this.form.markAllAsTouched();
    this.isLoading = true;

    if (this.form.invalid) {
      this.isLoading = false;
      return;
    }

    const url = new URL(window.location.href);

    this.kaufpreisApiService
      .submit({
        person: { ...this.form.getRawValue().person },
        legalCheck: { ...this.form.getRawValue().legalCheck },
        productDetails: {
          ...this.form.getRawValue().product,
          dateOfFirstRegistration: new Date(this.form.controls.product.controls.dateOfFirstRegistration.value as Date)
        },
        payment: { ...this.form.getRawValue().payment },
        insuranceType: InsuranceTypeEnum.KAUFPREIS,
        initialPrice: this.price,
        servoAdvertiserId: this.servoAdvertiserId,
        customerId: url.searchParams.get('customerId')
      } as NonNullable<KaupfreisSubmitModel>)
      .subscribe({
        next: () => this.submitted.emit(),
        complete: () => this.isLoading = false
      });
  }

  protected readonly InsuranceType = InsuranceType;
  protected readonly InsuranceTypeEnum = InsuranceTypeEnum;
}

export interface KaufpreisFormModel {
  person: FormGroup<PersonFormModel>;
  product: FormGroup<KaufpreisProductFormModel>;
  payment: FormGroup<PaymentFormModel>;
  legalCheck: FormGroup<KaufpreisLegalCheckFormModel>;
}

export interface KaufpreisProductFormModel {
  insuredObjectType: FormControl<KaufpreisInsuredObjectTypeEnum | null>;
  brand: FormControl<string | null>;
  model: FormControl<string | null>;
  fin: FormControl<string | null>;
  dateOfFirstRegistration: FormControl<Date | null>;
  mileage: FormControl<number | null>;
}

export interface KaufpreisLegalCheckFormModel {
  exclusiveInAustria: FormControl<boolean>;
  maximumCarWeightIsRespected: FormControl<boolean>;
  automatedInsuranceStopAfterTenYears: FormControl<boolean>;
  automatedInsuranceStopAfter200kkm: FormControl<boolean>;
  noBusinessUsage: FormControl<boolean>;

  communication: FormControl<boolean>;
  gdpr: FormControl<boolean>;
  commonConditions: FormControl<boolean>;
  informationLetter: FormControl<boolean>;
  sepaAllowed: FormControl<boolean>;
  rightOfWithdrawal: FormControl<boolean>;
  wishesAndNeedsFulfilled: FormControl<boolean>;
}
