<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Persönliche Daten</h4>

  <div class="row g-3">
    <div class="col-sm-6">
      <label
        for="firstName"
        class="form-label">Vorname</label>
      <input
        [formControl]="formGroup.controls.firstname"
        type="text"
        class="form-control"
        id="firstName" />
    </div>

    <div class="col-sm-6">
      <label
        for="lastName"
        class="form-label">Nachname</label>
      <input
        [formControl]="formGroup.controls.lastname"
        type="text"
        class="form-control"
        id="lastName" />
    </div>

    <div class="col-6">
      <label
        for="email"
        class="form-label">E-Mail</label>
      <input
        [formControl]="formGroup.controls.email"
        type="email"
        class="form-control"
        id="email"
        placeholder="you@example.com" />
    </div>

    <div class="col-6">
      <label
        for="phone"
        class="form-label">
        Telefon <span class="text-body-secondary">(optional)</span>
      </label>

      <input
        [formControl]="formGroup.controls.phone"
        type="tel"
        class="form-control"
        id="phone" />
    </div>

    <ng-container [formGroup]="formGroup.controls.address">
      <div class="col-8">
        <label
          for="address"
          class="form-label">Straße</label>

        <input
          [formControl]="formGroup.controls.address.controls.street"
          type="text"
          class="form-control"
          id="address" />
      </div>
      <div class="col-4">
        <label
          for="houseNumber"
          class="form-label">Hausnummer</label>

        <input
          [formControl]="formGroup.controls.address.controls.houseNumber"
          type="text"
          class="form-control"
          id="houseNumber" />
      </div>

      <div class="col-md-4">
        <label
          for="city"
          class="form-label">Ort</label>

        <input
          [formControl]="formGroup.controls.address.controls.city"
          type="text"
          class="form-control"
          id="city" />
      </div>

      <div class="col-md-3">
        <label
          for="zip"
          class="form-label">PLZ</label>

        <input
          [formControl]="formGroup.controls.address.controls.zip"
          type="text"
          class="form-control"
          id="zip" />
      </div>

      <div class="col-md-5">
        <label
          for="country"
          class="form-label">Land</label>

        <select
          [formControl]="formGroup.controls.address.controls.country"
          class="form-select"
          id="country">

          <option [ngValue]="CountryEnum.AUSTRIA">Österreich</option>
        </select>
      </div>
    </ng-container>
  </div>
</ng-container>
