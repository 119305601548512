import { Routes } from '@angular/router';
import { KaufpreisPageComponent } from './features/insurance/pages/kaufpreis-page/kaufpreis-page.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => KaufpreisPageComponent
  },
  {
    path: 'public',
    loadComponent: () => KaufpreisPageComponent
  },
  {
    path: 'stella',
    loadComponent: () => KaufpreisPageComponent
  }
];
