import { Component, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-insurance-details',
  standalone: true,
  imports: [
    CurrencyPipe
  ],
  templateUrl: './insurance-details.component.html',
  styleUrl: './insurance-details.component.scss'
})
export class InsuranceDetailsComponent {

  @Input() price: number | null = null;
  @Input() premium!: number;
  @Input() title!: string;

  @Input() premiumIntervalText = 'jährlich';
}
