import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { KaufpreisProductFormModel } from '../kaufpreis-form-wrapper/kaufpreis-form-wrapper.component';
import { KaufpreisInsuredObjectTypeEnum } from '../../models/kaufpreis-insured-object-type.enum';

@Component({
  selector: 'app-product-data',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './product-data.component.html',
  styleUrl: './product-data.component.scss'
})
export class ProductDataComponent {

  @Input() formGroup!: FormGroup<KaufpreisProductFormModel>;
  protected readonly KaufpreisInsuredObjectTypeEnum = KaufpreisInsuredObjectTypeEnum;
}
