import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { filter } from 'rxjs';

@Component({
  selector: 'app-garantie-calculator',
  standalone: true,
  imports: [
    CurrencyPipe,
    PaginatorModule,
    ReactiveFormsModule
  ],
  templateUrl: './garantie-calculator.component.html',
  styleUrl: './garantie-calculator.component.scss'
})
export class GarantieCalculatorComponent implements OnInit {

  @Output() requestOffer = new EventEmitter<{ isPremiumSchutz: boolean, premium: number }>();

  fb = inject(FormBuilder);

  form = this.fb.group<GarantieCalculatorFormModel>({
    isPremiumSchutz: new FormControl<boolean | null>(null, [Validators.required])
  });

  premium: number | null = null;

  ngOnInit() {
    this.form
      .controls
      .isPremiumSchutz
      .valueChanges
      .pipe(filter(value => value !== null && value !== undefined))
      .subscribe(value => this.setPremium(value!));
  }

  setPremium(isPremiumSchutz: boolean): void {
    if (isPremiumSchutz) {
      this.premium = 379;
    } else {
      this.premium = 199;
    }
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.requestOffer.emit({
      isPremiumSchutz: this.form.getRawValue().isPremiumSchutz!,
      premium: this.premium!
    });
  }
}

export interface GarantieCalculatorFormModel {
  isPremiumSchutz: FormControl<null | boolean>;
}
