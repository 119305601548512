<form
  (ngSubmit)="submit()"
  [formGroup]="form"
  class="servo-card">

  <div class="card-header">
    <div class="title">
      <h5 class="mb-0 d-flex">
        Berechnen Sie Ihre Prämie
      </h5>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="mb-2">
        <div class="col-12">
          <span class="fw-medium">
            Versichern Sie den Kaufpreis Ihres PKWs, Geländewagens, Transporters oder Wohnmobils
          </span>
        </div>
      </div>

      <div class="col-12 mb-3">
        <div class="mt-3">
          <label
            class="form-label"
            for="price">Kaufpreis (inkl. Zubehör)</label>

          <input
            [formControl]="form.controls.initialPrice"
            class="form-control form-control-sm"
            id="price"
            type="number">

          @if (form.controls.initialPrice.hasError('min') || form.controls.initialPrice.hasError('max')) {
            <div class="invalid-feedback">
              Preis muss zwischen 0,00 und 120.000 Euro liegen!
            </div>
          }
        </div>
      </div>

      @if (premium !== null && form.controls.initialPrice.valid) {
        <div class="col-12 mb-3">
          <div class="premium-wrapper">
            <div class="premium">
              {{ premium | currency:'EUR':'code':'1.2-2':'de-AT' }}
            </div>

            <small class="text-muted">
              (Jährliche Bruttoprämie)
            </small>
          </div>
        </div>

        <div class="col-12 mb-2">
          <button
            type="submit"
            class="btn btn-primary w-100">

            Jetzt abschließen
          </button>
        </div>
      }
    </div>
  </div>
</form>
