import { Injectable } from '@angular/core';

@Injectable()
export class KaufpeisPremiumCalculationService {

  getPremium(price: number): number | null {
    if (isNaN(price)) {
      return null;
    }

    if (price < 9999.99) {
      return 179;
    } else if (price < 14999.99) {
      return 219;
    } else if (price < 19999.99) {
      return 249;
    } else if (price < 34999.99) {
      return 299;
    } else if (price < 49999.99) {
      return 349;
    } else if (price < 64999.99) {
      return 399;
    } else if (price < 79999.99) {
      return 429;
    } else if (price < 99999.99) {
      return 449;
    } else if (price < 120000) {
      return 499;
    }

    return null;
  }
}
