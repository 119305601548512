import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LegalCheckFormModel } from '../garantie-form-wrapper/garantie-form-wrapper.component';

@Component({
  selector: 'app-legal-checks',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './legal-checks.component.html',
  styleUrl: './legal-checks.component.scss'
})
export class LegalChecksComponent {

  @Input() formGroup!: FormGroup<LegalCheckFormModel>;
}
