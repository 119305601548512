export enum PaymentPeriodEnum {
  MONTHLY = 'MONTHLY',
  QUARTER = 'QUARTER',
  HALFYEAR = 'HALFYEAR',
  YEARLY = 'YEARLY'
}

export const PaymentPeriod = {
  getTypeTranslation(paymentPeriod: PaymentPeriodEnum) {
    switch (paymentPeriod) {
      case PaymentPeriodEnum.YEARLY:
        return 'jährlich';

      case PaymentPeriodEnum.HALFYEAR:
        return 'halbjährlich';

      case PaymentPeriodEnum.QUARTER:
        return 'vierteljährlich';

      case PaymentPeriodEnum.MONTHLY:
        return 'monatlich';

      default:
        return '';
    }
  }
};
